<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>What's your interest - syllabus</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ What's your interest - Syllabus
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createInterestSyllabus()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add interest syllabus
                  </v-btn>
                  <v-btn
                      @click="syllabusInstrument()"
                      class="mt-1 ml-3 btn btn-primary"
                      style="color:#fff;"
                  >
                    Manage What's your interest - syllabus instrument
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
               <v-row>
                 <v-col cols="12" sm="6" md="3">
                   <v-text-field
                       label="Title"
                       v-model="search.title"
                       v-on:keyup.enter="searchInterestSyllabus"
                       @input="search.title = $event !== null ? $event : ''"
                       clearable
                       outlined
                       dense
                   ></v-text-field>
                 </v-col>

                 <v-col cols="12" md="3">
                   <v-select
                       label="Interest"
                       v-model="search.interest_id"
                       :items="interests"
                       item-text="title"
                       item-value="id"
                       @input="search.interest_id = $event !== null ? $event : ''"
                       clearable
                       outlined
                       dense
                   >
                   </v-select>
                 </v-col>

                 <v-col cols="12" sm="6" md="4">
                   <v-select
                       label="Instrument Available"
                       v-model="search.instrument_available"
                       v-on:keyup.enter="searchInterestSyllabus"
                       @input="search.instrument_available = $event !== null ? $event : ''"
                       :items="status"
                       item-text="name"
                       item-value="value"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>
                 <v-col cols="12" sm="6" md="2">
                   <v-btn
                       @click.prevent="searchInterestSyllabus"
                       v-on:keyup.enter="searchInterestSyllabus"
                       class="btn btn-block btn-primary"
                       style="color: #fff;"
                       :loading="loading"
                   >
                     <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                     Search
                   </v-btn>
                 </v-col>
               </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Title</th>
                  <th class="px-3">interest</th>
                  <th class="px-3">Instrument Available</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <tr v-for="(syllabus, index) in interestSyllabuses" :key="index">
                    <td class="px-3">
                      <a @click="editInterestSyllabus(syllabus)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ syllabus.title }}
                      </a>
                    </td>
                    <td class="px-3">
                        {{ syllabus.interest ? syllabus.interest : 'NA'}}
                    </td>

                    <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': syllabus.instrument_available, 'badge-danger': !syllabus.instrument_available }"
                        >{{ syllabus.instrument_available ? 'Yes' : 'No' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="editInterestSyllabus(syllabus)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteInterestSyllabus(syllabus.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="interestSyllabuses.length == 0">
                    <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                  </tr>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllInterestSyllabuses"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import InterestSyllabusService from "@/services/cms/interest/syllabus/InterestSyllabusService";
import InterestService from "@/services/cms/interest/InterestService";

const interestSyllabus = new InterestSyllabusService();
const interest = new InterestService();
export default {
  components: {
    CreateAndUpdate
  },
  data() {
    return{
      interestSyllabuses:[],
      interests:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      search:{
        title:'',
        interest_id:'',
        instrument_available:'',
      },
      status: [
        {name: 'Yes', value: '1'},
        {name: 'No', value: '0'},
      ],
    }
  },
  mounted() {
    this.getAllInterest();
    this.getAllInterestSyllabuses();
  },
  methods: {
    getAllInterest(){
      interest.all().then(response => {
        this.interests = response.data.interests;
      }).catch(() => {})
    },
    syllabusInstrument(){
      this.$router.push({
        name: "interest-syllabus-instrument",
      });
    },
    createInterestSyllabus(){
      this.$refs['create-and-update'].createInterestSyllabus();
    },
    editInterestSyllabus(interest){
      this.$refs['create-and-update'].editInterestSyllabus(interest);
    },
    getAllInterestSyllabuses(){
      this.loading = true;
      interestSyllabus
      .paginate(this.search, this.page)
      .then(response => {
        this.interestSyllabuses = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchInterestSyllabus(){
      this.getAllInterestSyllabuses();
    },
    deleteInterestSyllabus(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            interestSyllabus
                .delete(id)
                .then((response) => {
                  this.getAllInterestSyllabuses()
                  this.$snotify.success("Interest syllabus deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>