<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>{{ title }} Interest Syllabus</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="interestSyllabus.title"
                  :error="$v.interestSyllabus.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.interestSyllabus.title.$error">Title is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="interestSyllabus.sub_title"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Sub Title <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                  v-model="interestSyllabus.file"
                  @change="previewImage"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Image <span class="text-danger"></span>
                </template>
              </v-file-input>

              <v-img v-if="img" contain :src="img" max-width="150" max-height="150"></v-img>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="interestSyllabus.interest_id"
                  :items="interests"
                  item-text="title"
                  item-value="id"
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Interest <span class="text-danger"></span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="interestSyllabus.button_title"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Button Title <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="interestSyllabus.button_link"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Button Link <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              Show Check Eligibility
              <v-switch
                  v-model="interestSyllabus.show_check_eligibility"
                  :label="interestSyllabus.show_check_eligibility ? 'Yes' : 'No'"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4" v-if="interestSyllabus.show_check_eligibility">
              <v-text-field
                  v-model="interestSyllabus.eligibility_form_type"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Eligibility Text <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              Show Popup Form
              <v-switch
                  v-model="interestSyllabus.show_popup_form"
                  :label="interestSyllabus.show_popup_form ? 'Yes' : 'No'"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4" v-if="interestSyllabus.show_popup_form">
              <v-text-field
                  v-model="interestSyllabus.eligibility_text"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Eligibility Form Text <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              Show Url Link
              <v-switch
                  :label="interestSyllabus.show_url_link ? 'Yes' : 'No'"
                  v-model="interestSyllabus.show_url_link"
                  outlined
                  dense
              >
              </v-switch>
            </v-col>

            <v-col cols="12" md="4" v-if="interestSyllabus.show_url_link">
              <v-select
                  v-model="interestSyllabus.url_link_icon"
                  :items="linkIcons"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Url Link Icon <span class="text-danger"></span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" md="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="interestSyllabus.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.interestSyllabus.description.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12">
              Summary <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="interestSyllabus.summary_text"
              >
              </ckeditor>

            </v-col>

            <v-col cols="12" md="3">
              Instrument Available
              <v-switch
                  v-model="interestSyllabus.instrument_available"
                  :label="interestSyllabus.instrument_available ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, url } from "vuelidate/lib/validators";
import InterestSyllabusService from "@/services/cms/interest/syllabus/InterestSyllabusService";
import InterestService from "@/services/cms/interest/InterestService";

const interest = new InterestService();
const interestSyllabus = new InterestSyllabusService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      interests: [],
      img: null,
      interestSyllabus: {
        file: null,
        title: '',
        sub_title: '',
        interest_id: '',
        button_title: '',
        button_link: '',
        description: '',
        summary_text:'',
        instrument_available: false,
        show_check_eligibility: false,
        eligibility_form_type: '',
        show_url_link: false,
        url_link_icon: '',
        show_popup_form: false,
        eligibility_text: '',
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      linkIcons: [
        { name: 'Download', value: 'download' },
        { name: 'External Link', value: 'external_link' },
      ]
    }
  },
  validations: {
    interestSyllabus: {
      title: { required },
      description: { required }
    }
  },
  mounted() {
    this.getAllInterest()
  },
  methods: {
    closeDialog(){
      this.dialog = false;
      this.resetForm();
    },
    getAllInterest(){
      interest.all().then(response => {
        this.interests = response.data.interests;
      }).catch(() => {})
    },
    createInterestSyllabus(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editInterestSyllabus(interestSyllabus){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.interestSyllabus = interestSyllabus;
      if(this.interestSyllabus.image_path) {
        this.img = this.interestSyllabus.image_path.original;
      }else  {
        this.img = null;
      }
    },
    previewImage(){
      if(this.interestSyllabus.file){
        this.img = URL.createObjectURL(this.interestSyllabus.file)
      } else {
        this.img = null;
      }
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.interestSyllabus) {
        if(this.interestSyllabus[key]){
          formData.append(key,this.interestSyllabus[key]);
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this.create();
        else this.update();
      }
    },
    create: function() {
      let formData = this.convertToFormData();
      interestSyllabus
          .create(formData)
          .then(response =>{
            this.$snotify.success("Interest syllabus added");
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      let formData = this.convertToFormData();
      interestSyllabus
          .update(this.interestSyllabus.id, formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Interest syllabus updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.error = [];
      this.img = null;
      this.interestSyllabus = {
        file: null,
        title: '',
        sub_title: '',
        button_title: '',
        button_link: '',
        description: '',
        summary_text: '',
        instrument_available: false,
        show_check_eligibility: false,
        eligibility_form_type: '',
        show_url_link: false,
        url_link_icon: '',
        show_popup_form: false,
        eligibility_text: '',
      }
    }
  }
}
</script>

<style scoped>

</style>
